export default {
  phoneNumberValidator(phoneNo) {
    try {
      var result = null;
      phoneNo = phoneNo.toString();
      if (phoneNo.includes(",")) {
        result = phoneNo.split(",");
        phoneNo = result[0];
      } else if (phoneNo.includes("/")) {
        result = phoneNo.split("/");
        phoneNo = result[0];
      }
      return phoneNo.replace(/[^0-9+]/g, "");
    } catch (e) {
      return "";
    }
  },
  phoneNumberRegExp(phone) {
    try {
      // handle regexp here
    } catch (e) {
      // console.log("Errors",e)
    }
  },
};
