<template>
  <v-container>
    <v-flex v-if="productList.length <= 0" fluid class="whiteBackGround">
      <first-page
        :img-src="imgSrc"
        :dialog-name.sync="dialogProductAdd"
        :points="points"
        :headline="headline"
      ></first-page>
    </v-flex>

    <v-row v-if="productList.length > 0">
      <v-col cols="12" md="5">
        <v-flex style="margin-top: 20px;">
          <v-card flat class="pa-3">
            <v-row>
              <v-col cols="9">
                <v-text-field
                  v-model="search"
                  dense
                  flat
                  hide-details
                  rounded
                  solo-inverted
                  prepend-inner-icon="mdi-magnify"
                  label="Search Products"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-btn
                  rounded
                  flat
                  color="
                primarygrad"
                  @click="getProducts"
                  >GO</v-btn
                >
              </v-col>
            </v-row>
            <v-list two-line style="max-height: 65vh; overflow-y: auto;">
              <v-list-item-group mandatory color="green">
                <template v-for="product in productList">
                  <v-list-item
                    :key="product.id"
                    @click="selectedProductItem(product)"
                  >
                    <h1 class="px-2 font-weight-medium">
                      <v-icon :color="product.hex">fiber_manual_record</v-icon>
                    </h1>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="product.sku != ''">
                        {{ product.sku }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="product.price != 0">
                        {{ product.price }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-flex
                      xs2
                      sm2
                      md2
                      style="
                                  padding: 0px;
                                  margin-top: -7px;
                                  text-align: right !important;
                                "
                    >
                      <v-menu
                        origin="center center"
                        :close-on-content-click="false"
                        transition="scale-transition"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn small text fab v-on="on">
                            <v-icon size="20">more_vert</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="productEdit(product)">
                            <v-list-item-title>Edit</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="del(product)">
                            <v-list-item-title>Delete</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
            <!-- <v-list v-else two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No Products Found
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list> -->
          </v-card>
        </v-flex>
        <v-flex style="margin-top:20px">
          <v-btn
            depressed
            color="primarygrad"
            style="width:100%"
            @click="openDialog"
          >
            <v-icon>add</v-icon>Add Product
          </v-btn>
        </v-flex>
      </v-col>
      <v-col cols="12" md="7" style="margin-top: 20px;">
        <v-row v-if="totalCount > 0" class="pa-0 ma-0">
          <v-col cols="12" md="4" class="pa-1 ma-0">
            <v-card class="secondarygrad rounded fill-height py-16">
              <h2 class="text-center" style="line-height:1em;">
                {{ totalCount }}
              </h2>
              <h4 class="text-center font-weight-medium pt-2 secondary--text">
                Total Contacts
              </h4>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" class="pa-1 ma-0">
            <v-card class="secondarygrad rounded fill-height py-16">
              <h2 class="text-center" style="line-height:1em;">
                {{ totalRevenue }}
              </h2>
              <h4 class="text-center font-weight-medium pt-2 secondary--text">
                Total Revenue
              </h4>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" class="pa-1 ma-0">
            <v-card class="secondarygrad rounded fill-height py-4">
              <pie-chart
                style="height: 150px; width: 100%;"
                :chart-data="datacollection"
                :options="{
                  responsive: true,
                  legend: {
                    position: 'bottom',
                    labels: { fontColor: 'black' },
                    display: false,
                  },
                  maintainAspectRatio: false,
                }"
              />
            </v-card>
          </v-col>
        </v-row>

        <v-flex style="margin-top: 10px;">
          <v-data-table
            :headers="contactHeaders"
            :items="contactDetails"
            :items-per-page="records"
            hide-default-footer
            :loading="loading"
            :loading-text="loadingText"
            class="elevation-1 pa-5"
          ></v-data-table>

          <v-divider style="margin-bottom: 10px"></v-divider>
          <v-card-text style="text-align: center !important">
            <div class="v-data-footer">
              <div class="v-data-footer__select">
                Rows per page:
                <div
                  class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select"
                >
                  <v-select
                    v-model="records"
                    :items="recordsPerPage"
                    style="margin: 5px"
                    @change="pagination"
                  ></v-select>
                </div>
              </div>
              <div class="v-data-footer__icons-before">
                <v-btn
                  :disabled="page == 1 ? true : false"
                  color="primary"
                  fab
                  small
                  text
                  @click="prevSet"
                >
                  <v-icon :size="24">arrow_left</v-icon>
                </v-btn>
              </div>
              <div class="v-data-footer__pagination">
                {{ `${page}/${pageCount} of ${totalCount}` }}
              </div>
              <div class="v-data-footer__icons-after">
                <v-btn
                  :disabled="page >= pageCount ? true : false"
                  color="primary"
                  fab
                  small
                  text
                  @click="nextSet"
                >
                  <v-icon :size="24">arrow_right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-flex>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogProductAdd" width="450">
      <v-card>
        <v-card-title class="primary white--text">
          {{ formTitle }} Product
        </v-card-title>

        <v-card-text style="padding-top: 25px; padding-bottom: 25px">
          <v-flex xs12 sm12 md12>
            <v-text-field
              v-model="product.title"
              label="Name"
              :rules="rulesProductname"
              :value="product.title.toUpperCase()"
              @input="product.title = $event.toUpperCase()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-textarea
              v-model="product.desc"
              rows="3"
              label="Description"
              placeholder="(optional)"
            ></v-textarea>
          </v-flex>

          <v-text-field v-model="product.sku" label="SKU" placeholder="sku">
          </v-text-field>

          <v-text-field
            v-model="product.price"
            label="Price"
            placeholder="Price"
            type="number"
          >
          </v-text-field>

          <v-flex xs12 sm12 md12 style="text-align: center !important">
            <v-color-picker
              v-model="product.hex"
              hide-mode-switch
              mode="hexa"
              class="mx-auto"
            ></v-color-picker>
          </v-flex>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="dialogProductAdd = false"
            >Cancel</v-btn
          >
          <v-btn color="primary" @click="addProduct"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const _ = require("lodash");
import fb from "../js/firebase";
import validator from "../js/validator.js";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
//import Breadcrumbs from "../components/breadcrumbs.vue";
import PieChart from "../js/pie.js";
import firstPage from "@/components/firstPage.vue";

export default {
  components: {
    PieChart,
    firstPage,
  },
  data() {
    return {
      headline: "Manage Inventory & Boost Sales",
      points: [
        "Effortless product management: Add, update & manage products/services with ease.",
        "Smart tagging for telecallers: Tag customer interest in specific products during calls.",
        "Data-driven sales insights: Track revenue per product, identify buying trends.",
      ],
      recordsPerPage: [5, 20, 50, 100, 250, 500],
      records: 5,
      page: 1,
      pageCount: 0,
      loading: false,
      loadingText: "Loading..Please Wait",
      options: {},
      datacollection: [],
      totalCount: "",
      dialogProductAdd: false,
      productItem: {
        title: "",
        desc: "",
        hex: "",
        sku: "",
        price: "",
      },
      preloader: false,
      product: {
        title: "",
        desc: "",
        hex: "",
        sku: "",
        price: "",
      },
      selectedProduct: "",
      rulesProductname: [
        (v) =>
          /^\w+$/.test(v) ||
          "Maximum Fifteen characters & Must Not Contain Special Characters Or Space",
      ],
      productList: [],
      items: [],
      contactHeaders: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Phone",
          align: "left",
          sortable: false,
          value: "phone",
        },
        {
          text: "Disposition",
          align: "left",
          sortable: false,
          value: "dispo",
        },
        {
          text: "calledOn",
          align: "left",
          sortable: false,
          value: "formatedTime",
        },
      ],
      contactDetails: [],
      editedIndex: -1,
      totalRevenue: 0,
      search: "",
    };
  },
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "user",
      "dispoList",
      "fieldList",
      "teams",
      "selectedTeam",
      "selectedList",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    paginate() {
      return this.$store.getters.paginate;
    },
    imgSrc() {
      return require("../assets/products.svg");
    },
  },
  watch: {
    // productList() {
    //   this.preloader = false;
    // },
  },
  async created() {
    await this.getProducts();
    this.selectedProductItem(this.productList[0]);
  },

  methods: {
    isMobile() {
      if (window.innerWidth <= 760) {
        return true;
      } else {
        return false;
      }
    },
    /** PAGINATION */
    nextSet() {
      this.page++;
      this.pagination();
    },
    prevSet() {
      this.page--;
      this.pagination();
    },
    async pagination() {
      this.pageCount = Math.ceil(this.totalCount / this.records);
      if (this.page > this.pageCount) {
        this.page = 1; //set default
      }

      this.contactDetails = await this.fetchContactLogs(
        this.records,
        this.records * this.page - this.records,
        this.selectedProduct
      );

      // Save default pagination of seperate pages
      this.paginate[
        this.$router.currentRoute.name
          .toString()
          .trim()
          .replace(/ /g, "")
          .toLowerCase()
      ] = {
        page: this.page,
        pageCount: this.pageCount,
        records: this.records,
        totalCount: this.totalCount,
      };
      this.$store.commit("setPagination", this.paginate);
      return;
    },
    async totalContactCount(product) {
      var postObj = {
        productsId: [product.id],
        type: "count",
      };

      await this.$http
        .post(`${this.ENDPOINT}/products/get-contact-by-productIds`, postObj)
        .then((response) => {
          this.totalCount = response.body.count;
          this.pageCount = Math.ceil(this.totalCount / this.records);

          this.totalRevenue = product.price * this.totalCount;
        });
    },

    async totalGraphCount(product) {
      var self = this;
      var postObj = {
        productsId: [product.id],
        type: "graph",
      };

      this.$http
        .post(`${this.ENDPOINT}/products/get-contact-by-productIds`, postObj)
        .then((contacts) => {
          contacts = contacts.body;
          console.log("Contacts: ", contacts);
          var called = 0,
            uncalled = 0;
          _.each(contacts, (c) => {
            if (c._id == "NEW") uncalled += c.count;
            else called += c.count;
          });
          self.called = called;
          self.uncalled = uncalled;
          // console.log("Called: ",self.called);
          // console.log("Uncalled: ",self.uncalled);
          var colors = [];
          var labels = [];
          contacts = _.orderBy(contacts, ["count"], ["desc"]);
          if (contacts.length > 10) {
            var contactNew = contacts.slice(0, 10);
            var contactOther = {
              _id: "Others",
              count: 0,
            };
            for (let i = 10; i < contacts.length; i++) {
              contactOther.count += contacts[i].count;
            }
            contacts = contactNew.concat(contactOther);
          }

          console.log("contacts", contacts);

          _.each(contacts, (val) => {
            val = _.find(self.dispoList, (d) => {
              return d.title == val._id;
            });
            if (val == undefined) {
              colors.push("#31a848");
              labels.push("NEW");
            } else {
              colors.push(val.hex);
              labels.push(val.title);
            }
          });
          self.labelShown = false;
          // var labels = _.map(contacts, "_id");
          // Populate Chart
          self.datacollection = {
            datasets: [
              {
                backgroundColor: colors,
                data: _.map(contacts, "count"),
              },
            ],
            labels: labels,
          };
        });
    },

    async fetchContactLogs(limit, skip, product) {
      try {
        this.loading = true;
        this.loadingText = "Loading... Please wait";

        if (limit == undefined || limit == undefined) {
          limit = 5;
          skip = 0;
        }

        var response = await this.$http.post(
          `${this.ENDPOINT}/products/get-contact-by-productIds`,
          {
            limit: limit,
            skip: skip,
            productsId: [product.id],
            type: "data",
          }
        );

        response = _.without(
          _.map(response.body, (r) => {
            if (r.calledOn != undefined) {
              r.formatedTime = moment(r.calledOn).format("YYYY-MM-DD hh:mm A");
            }
            return r;
          }),
          undefined
        );
        response = _.orderBy(response, "calledOn", "desc");

        this.loading = false;
        this.loadingText = "";
        return response;
      } catch (e) {
        this.loading = false;
        this.loadingText = "Something went wrong while fetching call logs";
      }
    },

    async selectedProductItem(product) {
      this.contactDetails = [];

      await this.totalContactCount(product);

      await this.totalGraphCount(product);

      this.contactDetails = await this.fetchContactLogs(
        this.records,
        this.records * this.page - this.records,
        product
      );
      this.selectedProduct = product;
    },
    openDialog() {
      this.editedIndex = -1;
      this.product = {
        title: "",
        desc: "",
        hex: "",
        sku: "",
        price: "",
      };
      this.dialogProductAdd = true;
    },
    uppercase() {
      this.product.title = this.product.title.toUpperCase();
    },
    productEdit(item) {
      console.log(item);
      this.editedIndex = this.productList.indexOf(item);
      this.product = Object.assign(item);
      this.dialogProductAdd = true;
    },
    updateProductName() {
      const self = this;

      if (this.productEditedItem.title == "") {
        return self.$swal({
          type: "error",
          text: "Please Enter Product Name",
        });
      }

      self.$http
        .patch(
          `${this.$store.state.ENDPOINT}/products/${self.productEditedItem.id}`,
          self.productEditedItem
        )
        .then(
          (response) => {
            self.dialogProductEdit = false;
            self.getProducts();
            self.$swal({
              type: "success",
              text: "Product Updated Successfully",
            });
          },
          (error) => {
            self.$swal({
              type: "warning",
              text: "Error occured. Please try again.",
            });
          }
        );
    },

    async getProducts() {
      const self = this;
      var filter = {
        where: {
          companyId: this.$store.getters.user.companyId,
        },
      };
      if (this.search != "") {
        filter.where.title = { like: `${this.search}*`, options: "i" };
      }

      try {
        var response = await this.$http.get(
          `${self.$store.state.ENDPOINT}/products?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`
        );
        self.productList = response.body;

        self.loader = false;
      } catch (err) {
        console.log("Error", err);

        self.loader = false;

        self.$swal({
          type: "error",
          text: "Something went wrong. Please try again",
        });
      }
    },

    addProduct() {
      var self = this;
      if (this.product.title == "") {
        return self.$swal({
          type: "error",
          text: "Please Enter Product Name",
        });
      }

      console.log(
        "this.product.title",
        this.product.title.toUpperCase().trim()
      );

      this.product.title = this.product.title.toUpperCase().trim();
      this.preloader = true;

      if (this.editedIndex > -1) {
        var jsonObj = {
          title: this.product.title,
          hex: this.product.hex,
          desc: this.product.desc,
          sku: this.product.sku,
          price: this.product.price,
          companyId: this.$store.getters.user.companyId,
        };

        self.$http
          .patch(
            `${this.$store.state.ENDPOINT}/products/${self.product.id}`,
            jsonObj
          )
          .then(
            (response) => {
              this.dialogProductAdd = false;
              self.getProducts();
              self.$swal({
                type: "success",
                text: "Product Updated Successfully",
              });
            },
            (error) => {
              self.$swal({
                type: "warning",
                text: "Error occured. Please try again.",
              });
            }
          );
      } else {
        this.product.companyId = this.$store.getters.user.companyId;

        this.$http
          .post(`${this.$store.state.ENDPOINT}/products`, this.product)
          .then((response) => {
            this.product = {};
            this.product.title = "";
            this.product.hex = "";
            this.product.desc = "";
            this.product.sku = "";
            this.product.price = "";
            this.preloader = false;
            this.dialogProductAdd = false;
            this.getProducts();

            self.$swal({
              type: "success",
              text: "Product Added Successfully",
            });

            fb.log("create_products");
          })
          .catch((e) => {
            this.preloader = false;
            this.$swal({
              type: "error",
              text: "Sorry, same product is already present",
            });
          });
      }
    },
    del(item) {
      this.preloader = true;

      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Delete this product?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "cancel",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete(`${this.$store.state.ENDPOINT}/products/${item.id}`)
              .then((response) => {
                this.preloader = false;
                this.getProducts();
                // this.$store.dispatch("getProducts", {
                //   companyId: this.$store.getters.user.companyId,
                // });
                fb.log("delete_product");
              })
              .catch((e) => {
                this.preloader = false;
                console.log(e);
              });
          }
          this.preloader = false;
        });
    },
  },
};
</script>

<style scoped>
.widthCustom {
  max-width: 600px !important;
}

.v-color-picker__canvas {
  width: 600px !important;
}

#pie-chart {
  display: block;
  height: 236px;
  width: 236px;
}

.cs-box {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.image-container img {
  width: auto;
  /* Change this to auto to maintain aspect ratio */
  max-width: 100%;
  /* Prevents the image from exceeding the container width */
  height: auto;
}

.text-transform {
  text-transform: capitalize;
}

.whiteBackGround {
  background-color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-margin {
  margin: 0;
  /* Override any default margin */
}

@media (max-width: 760px) {
  .image-container {
    margin-bottom: 20px;
    /* Adds spacing between the image and text content on mobile */
  }
}

.customRadiusDialog {
  border-radius: 0px !important;
}

.v-application .color-avatar {
  cursor: pointer;
  border: 4px solid transparent;
  /* Maintains layout consistency */
  border-radius: 50%;
  /* Ensures the avatar is round */
  transition: border-color 0.3s;
}

.v-application .active-border {
  border-color: rgb(188 188 188) !important;
  /* Overrides Vuetify theme color */
}

.v-card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.v-simple-table td {
  vertical-align: middle;
}

.v-img {
  max-width: 50px;
  height: auto;
  display: inline-block;
}

.green-row {
  background-color: #cffede;
  /* Light green color */
}

.v-text-field__details {
  display: none;
}
</style>
